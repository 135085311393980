.new_program_card {
  .MuiPaper-root {
    .MuiCardHeader-root {
      .MuiCardHeader-content {
        .MuiTypography-root.MuiCardHeader-title {
          font-size: 24px;
          font-weight: bold;
          color: white;
          border-bottom: 1px solid rgba(228, 228, 228, 0.25);
          font-family: Open Sans;
        }
      }
      .MuiCardHeader-action {
        color: #bbbbbb;
      }
    }
  }
}
