$global_font: "Open Sans";

.program_cards {
  div {
    div {
      .MuiPaper-root {
        .MuiCardHeader-root {
          padding-top: 0;

          .MuiCardHeader-content {
            display        : flex;
            justify-content: space-between;

            .MuiCardHeader-title {
              color      : #111111;
              font-size  : 20px;
              font-weight: bold;
              font-family: $global_font;
            }

            .MuiCardHeader-subheader {
              font-size  : 20px;
              font-weight: bold;
              font-family: $global_font;
              color      : #48556b;
              opacity    : 0.6;
            }
          }
        }

        .MuiCardContent-root {
          div {
            h3 {
              font-size  : 24px;
              font-weight: bold;
              font-family: "Montserrat";
              color      : #fe5722;
            }

            span {
              span:first-child {
                background: linear-gradient(90deg, #fb7514, #f89405);
              }

              span:nth-child(2) {
                background: linear-gradient(90deg, #fd6022, #fb7514);
              }
            }

            div {
              font-size  : 12px;
              font-weight: normal;
              font-family: $global_font;
              color      : #666666;

              span {
                font-size  : 14px;
                font-weight: bold;
                color      : #333333;
              }
            }
          }
        }
      }
    }

    .program_card_type0 {
      .MuiPaper-root {
        .MuiCardContent-root {
          div {
            h3 {
              font-size  : 24px;
              font-weight: bold;
              font-family: "Montserrat";
              color      : #fe5722;
            }

            span {
              span:first-child {
                background: linear-gradient(90deg, #fb7514, #f89405);
              }

              span:nth-child(2) {
                background: linear-gradient(90deg, #fd6022, #fb7514);
              }
            }

            div {
              font-size  : 12px;
              font-weight: normal;
              font-family: $global_font;
              color      : #666666;

              span {
                font-size  : 14px;
                font-weight: bold;
                color      : #333333;
              }
            }
          }
        }
      }
    }

    .program_card_type1 {
      .MuiPaper-root {
        .MuiCardContent-root {
          div {
            h3 {
              font-size  : 24px;
              font-weight: bold;
              font-family: "Montserrat";
              color      : #00afa5;
            }

            span {
              span:first-child {
                background: linear-gradient(90deg, #0eb79a, #61e754);
              }

              span:nth-child(2) {
                background: linear-gradient(90deg, #0eb69d, #0eb79a);
              }
            }

            div {
              font-size  : 12px;
              font-weight: normal;
              font-family: $global_font;
              color      : #666666;

              span {
                font-size  : 14px;
                font-weight: bold;
                color      : #333333;
              }
            }
          }
        }
      }
    }

    .program_card_type2 {
      .MuiPaper-root {
        .MuiCardContent-root {
          div {
            h3 {
              font-size  : 24px;
              font-weight: bold;
              font-family: "Montserrat";
              color      : #794df6;
            }

            span {
              span:first-child {
                background: linear-gradient(90deg, #695df6, #0dc4f8);
              }

              span:nth-child(2) {
                background: linear-gradient(90deg, #794ef6, #695df6);
              }
            }

            div {
              font-size  : 12px;
              font-weight: normal;
              font-family: $global_font;
              color      : #666666;

              span {
                font-size  : 14px;
                font-weight: bold;
                color      : #333333;
              }
            }
          }
        }
      }
    }
  }
}