form{
    .form-row{
        .form-group{
            .form-label{
                font-size: 20px;
                font-weight: bold;
                color: #111111;
                opacity: 0.6;
            }
            .form-control{
                font-size: 20px;
                font-weight: normal;
                color: #707070;
                height: 52px;
            }
            .form-control::placeholder{
                color: #707070;
            }
        }
    }
}

label.MuiFormControlLabel-root{
    .MuiCheckbox-colorPrimary.Mui-checked{
        color: "#36425c";
    }
    .MuiFormControlLabel-label{
        font-size: 20px;
        font-weight: bold;
        color: rgba(17, 17, 17, 0.6);
    }
} 