* {
    box-sizing: border-box;
  }
  
  .Board {
    font-family: sans-serif;
    white-space: nowrap;
  }
  
  .Column__container {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 555px;
    padding: 20px 30px;
    border-radius: 5px;
    /* margin-right: 20px; */
    background: white;
    color: #bfdbf7;
    margin-bottom: 28px;
  }
  
  .add_column.Column__container {
    background: none;
    border: 1px dashed #707070;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Column__leader .Column{
    height: 60px;
    /* margin-bottom: 10px; */
  }
  .ColumnLeaderBound{
    height: 6px;
    border-radius: 3px;
    background: linear-gradient(90deg, #FE5722, #F89106);
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .ColumnContent{
    height: 325px;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .Column__leader .ColumnContent{
    height: auto;
  }

  .Column__title {
    font-size: 25px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 10px;
  }
  
  .Card {
    padding: 15px;
    background: none;
    color: #f4fffd;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #707070;
    cursor: move;
    height: 58px;
  }
  
  .Card--spacer,
  .Card--dragging {
    opacity: 0;
  }
  
  .TextForm__input {
    display: block;
    background: #f4fffd;
    width: 100%;
    border-radius: 3px;
    border: none;
    font-size: 16px;
    padding: 5px;
    margin-bottom: 5px;
    opacity: 0.7;
  }
  
  .TextForm__input:focus {
    opacity: 1;
  }

  .Column{
    border: 1px dashed #707070;
    border-radius: 5px;
  }

  .available_leaders .Column, 
  .available_students .Column{
    margin-bottom: 16px;
    height: 60px;
  }

  .available_leaders .Column .ColumnContent, 
  .available_students .Column .ColumnContent{
    height: 68px;
  }

  