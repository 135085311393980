.react-daterange-picker{
    width: 100%;
}

.react-daterange-picker__wrapper{
    height: 50px;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 4px;
    border-color: #bfbfbf;
    width: 100%;
}
/* 
.react-daterange-picker__wrapper:hover{
    border-color: black;
} */

.react-daterange-picker__wrapper:focus{
    border: 2px solid #4ea8f9;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.15);
}

.react-daterange-picker__range-divider{
    padding-top: 14px;
}