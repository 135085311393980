.MuiFormControlLabel-root .MuiFormControlLabel-label {
    color      : #666666;
    font-size  : 12px;
    font-weight: normal;
}

.programchart_title .breadcrumb {
    background: none;

    li {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
    }
}