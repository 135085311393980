@media (min-width: 1024px) {
  .btn.btn_signin_home {
    margin-left: 20px;
    /* margin-right: 5px; */
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
  }
  .btn.btn_signout_home {
    margin-left: 5px;
    /* margin-right: 20px; */
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
  }

}

.navbar-nav > .my_nav_link{
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  color: black !important;
  font-weight: 400;
}

.my_nav_link:hover{
  color: #439bef !important;
}

.navbar-expand-lg .navbar-nav .nav-link{
  padding-top: 10px;
}

#root > div > .navbar{
  padding-top: 18px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn.btn_signin_home {
  padding-left: 20px;
  padding-right: 20px;
}
.btn.btn_signout_home {
  padding-left: 20px;
  padding-right: 20px;
}
