$global_font: "Open Sans";

.dashboard_statistics {
  div:first-child {
    .MuiPaper-root {
      .MuiCardHeader-root {
        padding-top: 0;
        .MuiCardHeader-content {
          .MuiTypography-root {
            color: #111111;
            font-size: 20px;
            font-weight: bold;
            font-family: $global_font;
          }
        }
      }
      .MuiCardContent-root {
        div {
          h3 {
            font-size: 24px;
            font-weight: bold;
            font-family: "Montserrat";
            color: #fe5722;
            margin-bottom: 0;
          }
          span {
            span:first-child {
              background: linear-gradient(90deg, #fb7514, #f89405);
            }
            span:nth-child(2) {
              background: linear-gradient(90deg, #fd6022, #fb7514);
            }
          }
          div {
            font-size: 12px;
            font-weight: normal;
            font-family: $global_font;
            color: #666666;
            span {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
          }
        }
      }
    }
  }
  div:nth-child(2) {
    .MuiPaper-root {
      .MuiCardHeader-root {
        padding-top: 0;
        .MuiCardHeader-content {
          .MuiTypography-root {
            color: #111111;
            font-size: 14px;
            font-weight: 500;
            font-family: $global_font;
          }
        }
      }
      .MuiCardContent-root {
        div {
          h3 {
            font-size: 24px;
            font-weight: bold;
            font-family: "Montserrat";
            color: #00afa5;
            margin-bottom: 0;
          }
          span {
            span:first-child {
              background: linear-gradient(90deg, #0eb79a, #61e754);
            }
            span:nth-child(2) {
              background: linear-gradient(90deg, #0eb69d, #0eb79a);
            }
          }
          div {
            font-size: 12px;
            font-weight: normal;
            font-family: $global_font;
            color: #666666;
            span {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
          }
        }
      }
    }
  }
  div:nth-child(3) {
    .MuiPaper-root {
      .MuiCardHeader-root {
        padding-top: 0;
        .MuiCardHeader-content {
          .MuiTypography-root {
            color: #111111;
            font-size: 14px;
            font-weight: 500;
            font-family: $global_font;
          }
        }
      }
      .MuiCardContent-root {
        div {
          h3 {
            font-size: 24px;
            font-weight: bold;
            font-family: "Montserrat";
            color: #794DF6;
            margin-bottom: 0;
          }
          span {
            span:first-child {
              background: linear-gradient(90deg, #695DF6, #0DC4F8);
            }
            span:nth-child(2) {
              background: linear-gradient(90deg, #794EF6, #695DF6);
            }
          }
          div {
            font-size: 12px;
            font-weight: normal;
            font-family: $global_font;
            color: #666666;
            span {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
