#root,
.MuiTableRow-root .MuiTableCell-root,
button.MuiButton-root,
.MuiFormControlLabel-root .MuiTypography-body1,
span.MuiTab-wrapper,
.MuiTypography-root.MuiTypography-body1 {
  font-family: "Open Sans";
}

.MuiBadge-badge.MuiBadge-colorSecondary {
  color           : #ffffff;
  background-color: #DD352E;
}

.MuiButtonBase-root.MuiIconButton-colorInherit {
  color: white;
}

span.MuiTab-wrapper {
  text-transform: none;
  font-size     : 26px;
  font-weight   : normal;
  color         : #111111;
}

.MuiTabs-indicator.MuiTabs-indicator {
  background-color: #36435C;
  height          : 6px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  text-transform: none;
}

.MuiCardContent-root .MuiSlider-root {
  padding: 0;
}


/* width */
::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";