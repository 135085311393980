.home_container {
  background: white;
}
.content_section {
  /* max-width: 1400px; */
  /* margin: 0 auto; */
}
.about_container {
  background-size: cover;
  padding-bottom: 232px;
}

.div_about_middle {
  padding-top: 96px;
}

.div_about_middle > p:first-child {
  border-left: 2px solid white;
  color: white;
  padding-left: 66px;
  padding-bottom: 40px;
}

.div_about_middle > h1 {
  font-size: 60px;
  color: white;
  margin-top: 154px;
}

.div_about_middle > p:last-child {
  color: white;
  margin-top: 38px;
  width: 60%;
}

.program_container {
  padding-top: 163px;
  padding-bottom: 50px;
}

.program_bar {
  width: 100px;
  border-top: 1px solid #636363;
}

.program_right > p {
  color: #4ca9f9;
  font-size: 14px;
  font-weight: bold;
  font-family: "Open Sans";
}

.program_right > h1 {
  color: #4f4f6f;
  font-size: 48px;
  font-family: "Open Sans";
  word-break: break-all;
}

@media (max-width: 768px) {
  .div_about_middle > p:last-child {
    width: 100%;
  }
}
