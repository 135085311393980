div.mapOverBar {
    div {
        div {
            div.MuiInputBase-root {
                input{
                    font-size: 9px;
                }
                input::placeholder {
                    font-size: 9px;
                }
            }
        }
    }
}