label.MuiFormControlLabel-root {
  margin-right: 0;
  .MuiButtonBase-root{
      padding: 5px;
  }
  .MuiFormControlLabel-label {
    color: #333333;
    font-size: 14px;
    font-weight: normal;
  }
}

#category_chart{
  margin: 0 auto;
}